/* navbar1 */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

body {
    font-family: "Poppins", sans-serif;
}
#navbar1{
    display: flex;
    justify-content: space-around;
    padding: 20px;
    box-shadow: 0px 1px 3px -2px;
    background-color: #254E70;
}

#nav-logo{
  width: 200px;
  height: 115px;
}
#nav-search{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
}

@media screen and (max-width: 1400px){
    /* .nav-search{
        max-width: 300px;
    } */
    #nav-logo{
        width: 125px;
        height: 75px;
    }
    .nav-login-btn a{
        padding-left: 30px;
    }
}
@media screen and (max-width: 1000px){
  /* .nav-search{
      max-width: 300px;
  } */
  #nav-logo{
      width: 105px;
      height: 45px;
  }
  .nav-login-btn a{
      padding-left: 20px;
  }
}
/* .nav-search input{
    width: 600px;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    flex: 1;
} */
.search{
    padding: 10px 20px;
    background-color: #37718E;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-left: 10px; 
    cursor: pointer;
}

.search:hover {
    background-color: #8EE3EF   ; 
}

.nav-login-btn{
    display: flex;
    align-items: center;    
    padding-right: 20px;
    font-weight: bold; 
}
.nav-login-btn a{
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    transition: color 0.3s ease;
    
}
.nav-login-btn a:hover{
    color: #8EE3EF;
}
/* navbar2 */
#navbar2 {
    display: flex;
    justify-content: space-between;
    background-color: #254E70;
    margin-top: 2px;
  }
  
  /* categories dropdown */
  .nav-dropdown {
    position: relative;
    display: inline-block;
    padding: 15px;
    padding-left: 40px;
  }
  
  .nav-dropdown span {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    cursor: pointer; /* Add cursor pointer for better UX */
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    top: 100%; /* Position below the trigger element with some spacing */
    left: 0; /* Align with the trigger element */
    background-color: #8EE3EF;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10; /* Ensure dropdown content appears on top */
  }
  
  /* Adjusted visibility when hovering over trigger or content */
  .nav-dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-content a {
    display: block;
    color: black;
    padding: 12px 12px;
    text-decoration: none;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }

#navbar{
    padding-right: 1rem ;
}
@media screen and (max-width: 750px){
  .navbar{
      padding-right: 2rem ;
  }
  #nav-logo{
    width: 100px;
    height: 40px;
}.nav-login-btn a{
    padding-left: 20px;
}
}
@media screen and (max-width: 500px){
    .navbar{
        padding-right: 2rem ;
    }
    #nav-logo{
      width: 100px;
      height: 40px;
  }.nav-login-btn a{
      padding-left: 20px;
      font-size: 10px;
  }
  .nav-dropdown {
    padding: 10px;
    padding-left: 60px;
  }
  
  .nav-dropdown span {
    font-size: 12px;
  }
}

.navbar-brand {
    font-size: 24px;
  }
  
  .navbar-nav .nav-link {
    font-size: 18px;
    padding: 10px 15px;
    color: #fff;
    font-weight: bold;
  }
  
  .navbar-nav .nav-item.active .nav-link {
    color: #8EE3EF; /* Active link color */
  }
  .navbar-nav .nav-item.active .nav-link {
    position: relative;
  }
  
  .navbar-nav .nav-item.active .nav-link::after {
    content: '';
    position: absolute;
    bottom: -1px; /* Adjust the thickness of the bar */
    left: 0;
    width: 100%;
    height: 3px; /* Adjust the height of the bar */
    background-color: #fff; /* Color of the bar */
  }
  
  .navbar-nav .nav-item:hover .nav-link {
    color: #888; /* Hover link color */
  }

  .username-logout-wrapper {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust the value to your preference */
  }

  #logoutbtn{
    color: #ffffff;
  }
  #logoutbtn:hover{
    color: red;
  }
  