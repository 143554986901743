@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://kit-free.fontawesome.com/releases/latest/css/free.min.css');

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
}

.sidebar-container {
  display: flex;
}

.toggle-btn {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 1000;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #254E70;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: auto;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  z-index: 999;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; /* Adjust the height as needed */
  padding: 10px;
  background-color: #254E70; /* Same background color as sidebar */
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

.sidebar__highlight {
  height: 55px;
  width: 200px;
  background-color: #6A86D9;
  position: absolute;
  transition: all 0.2s cubic-bezier(0,0,1,1);
}

.sidebar__highlight__animate {
  animation: test 0.2s cubic-bezier(0,0,1,1) 0s forwards;
}

@keyframes test {
  0% { height: 55px; width: 55px; background-color: #6A86D9; border-radius: 50%; }
  50% { border-radius: 0 25px 25px 0; width: 70px; }
  100% { background-color: #62D9D9; width: 200px; border-radius: 0; }
}

.sidebar a {
  display: block;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
}

.sidebar a span {
  font-size: 14px;
  position: relative;
  z-index: 999;
  transition: all 0.2s;
}

.sidebar a span i {
  opacity: 0;
  transition: opacity 0.5s;
}

.sidebar a:hover span {
  margin-left: 10px;
}

.sidebar a:hover span i {
  opacity: 1;
}

.sidebar a.active {
  color: white;
}

.sidebar a.active span i {
  opacity: 1;
}

.text-active {
  margin-left: 10px;
}

.sidebar.open {
  transform: translateX(0);
}
.content-container {
  padding: 20px;
  margin-left: 200px;
  width: calc(100% - 200px);
  transition: margin-left 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .toggle-btn {
    display: block;
  }

  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.closed {
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .content-container {
    padding: 10px;
    margin-left:20px;
    width: 100%; /* Reset the margin when sidebar is closed */
  }

  .content-container.sidebar-open {
    margin-left: 200px; /* Adjust the margin when sidebar is open */
  }
}

.app-container {
  display: flex; 
}
