@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

body {
    
    color: #ffffff;
}

.topcat-container {
    font-family: "Poppins", sans-serif;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 100px;
    color: #000000;
}

.topcat-row{
    margin-top: 20px;
}

.category-item p{
    color: #000000 ;
}

@media screen and (max-width: 500px){
    .topcat-container {
        padding-left: 50px;
        margin-bottom: 50px;
    }
}