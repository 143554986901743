.topcat{
    display: none;
  }
  
  @media (max-width: 900px){
    .topcat{
      display: block;
    }
  }

